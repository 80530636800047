.home-page-info-section .bccampus-page-section-content {
  padding: var(--home-page-section-content-pading);
}

.home-page-info-section .bccampus-page-section-container li {
  margin-bottom: 1em;
}

#page-hero img {
  display: var(--home-page-hero-image-display);
}
